import React, { useEffect, useRef, useState } from 'react';
import p5 from 'p5';
import './ThreeBodyProblem.css';
import LessonLayout from '../LessonLayout';

const ThreeBodyProblem = () => {
  const sketchRef = useRef();
  const [gravity, setGravity] = useState(100);
  const [trailLength, setTrailLength] = useState(200);

  useEffect(() => {
    let bodies = [];
    let trails = [[], [], []];

    const sketch = (p) => {
      p.setup = () => {
        p.createCanvas(600, 400);
        bodies = [
          { x: 200, y: 200, vx: 0, vy: 0, m: 10, color: p.color(255, 0, 0) },
          { x: 400, y: 200, vx: 0, vy: 0, m: 10, color: p.color(0, 255, 0) },
          { x: 300, y: 300, vx: 0, vy: 0, m: 10, color: p.color(0, 0, 255) }
        ];
      };

      p.draw = () => {
        p.background(220);

        // Update positions and velocities
        for (let i = 0; i < bodies.length; i++) {
          let ax = 0, ay = 0;
          for (let j = 0; j < bodies.length; j++) {
            if (i !== j) {
              let dx = bodies[j].x - bodies[i].x;
              let dy = bodies[j].y - bodies[i].y;
              let r = p.sqrt(dx*dx + dy*dy);
              let f = gravity * bodies[i].m * bodies[j].m / (r * r);
              ax += f * dx / r;
              ay += f * dy / r;
            }
          }
          bodies[i].vx += ax / bodies[i].m;
          bodies[i].vy += ay / bodies[i].m;
          bodies[i].x += bodies[i].vx;
          bodies[i].y += bodies[i].vy;

          // Add current position to trail
          trails[i].push({x: bodies[i].x, y: bodies[i].y});
          if (trails[i].length > trailLength) {
            trails[i].shift();
          }
        }

        // Draw trails
        for (let i = 0; i < trails.length; i++) {
          p.stroke(bodies[i].color);
          p.noFill();
          p.beginShape();
          for (let pos of trails[i]) {
            p.vertex(pos.x, pos.y);
          }
          p.endShape();
        }

        // Draw bodies
        for (let body of bodies) {
          p.fill(body.color);
          p.noStroke();
          p.ellipse(body.x, body.y, 20, 20);
        }
      };
    };

    const p5Instance = new p5(sketch, sketchRef.current);

    return () => {
      p5Instance.remove();
    };
  }, [gravity, trailLength]);

  const content = (
    <div className="three-body-problem">
      <h1>The Three-Body Problem: A Cosmic Dance of Chaos</h1>
      
      <section className="introduction">
        <h2>What is the Three-Body Problem?</h2>
        <p>The three-body problem is a classic challenge in physics that involves predicting the motion of three objects interacting through gravitational forces. Unlike the simple two-body problem, which has a straightforward solution, the three-body problem is famously difficult and can lead to chaotic behavior.</p>
      </section>

      <section className="simulation">
        <h2>Watch the Cosmic Ballet</h2>
        <p>Below is a simplified simulation of three bodies interacting gravitationally. Observe how their paths intertwine and change unpredictably over time.</p>
        <div ref={sketchRef}></div>
        <div className="controls">
          <label>
            Gravity Strength:
            <input 
              type="range" 
              min="10" 
              max="500" 
              value={gravity} 
              onChange={(e) => setGravity(Number(e.target.value))}
            />
          </label>
          <label>
            Trail Length:
            <input 
              type="range" 
              min="50" 
              max="500" 
              value={trailLength} 
              onChange={(e) => setTrailLength(Number(e.target.value))}
            />
          </label>
        </div>
      </section>

      <section className="explanation">
        <h2>The Physics Behind the Dance</h2>
        <p>The three-body problem is governed by these key factors:</p>
        <ul>
          <li><strong>Gravity:</strong> The force attracting each body to the others.</li>
          <li><strong>Mass:</strong> The amount of matter in each body, affecting its gravitational pull.</li>
          <li><strong>Initial conditions:</strong> The starting positions and velocities of the bodies.</li>
          <li><strong>Time:</strong> As time passes, small differences in initial conditions can lead to vastly different outcomes.</li>
        </ul>
        <p>The challenge arises because each body's motion is influenced by the ever-changing positions of the other two, creating a complex, interconnected system.</p>
      </section>

      <section className="real-world">
        <h2>Why It Matters</h2>
        <p>The three-body problem has significant implications in various fields:</p>
        <ul>
          <li><strong>Astronomy:</strong> Understanding the motions of celestial bodies, like planets around binary star systems.</li>
          <li><strong>Spacecraft navigation:</strong> Planning trajectories for space missions involving multiple gravitational influences.</li>
          <li><strong>Chaos theory:</strong> Illustrating how deterministic systems can produce unpredictable results.</li>
          <li><strong>Mathematics:</strong> Inspiring new approaches to solving differential equations and dynamical systems.</li>
        </ul>
      </section>

      <section className="experiment">
        <h2>Your Turn to Explore</h2>
        <p>Use the sliders above the simulation to adjust the gravity strength and trail length. As you experiment, consider these questions:</p>
        <ul>
          <li>How does changing the gravity strength affect the stability of the system?</li>
          <li>Can you find settings that create stable orbits? How about settings that cause bodies to fly apart?</li>
          <li>What patterns do you notice in the trails left by each body?</li>
          <li>How sensitive is the system to small changes in the controls?</li>
        </ul>
      </section>
      
      <section className="conclusion">
        <h2>Wrapping Up</h2>
        <p>The three-body problem stands as a testament to the complexity hidden within seemingly simple physical laws. It reminds us that in nature, the interaction of just a few elements can lead to rich, unpredictable behaviors. As you continue to explore physics, remember the three-body problem as a humbling example of the mysteries that still challenge our understanding of the universe.</p>
      </section>
    </div>
  );

  return (
    <LessonLayout title="The Cosmic Dance: Exploring the Three-Body Problem">
      {content}
    </LessonLayout>
  );
};

export default ThreeBodyProblem;
