import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './LessonLayout.css';

const LessonLayout = ({ children, title }) => {
  const navigate = useNavigate();

  return (
    <div className="lesson-layout">
      <header className="lesson-header">
        <div className="header-content">
          <button onClick={() => navigate(-1)} className="back-button">
            ← Back
          </button>
          <h1>{title}</h1>
          <Link to="/" className="home-link">Home</Link>
        </div>
      </header>
      <main className="lesson-content">
        {children}
      </main>
      <footer className="lesson-footer">
        <p>© 2024 SimulationBook. Keep learning and exploring!</p>
      </footer>
    </div>
  );
};

export default LessonLayout;
