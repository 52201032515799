import React, { useEffect, useRef, useState } from 'react';
import p5 from 'p5';
import './SimplePendulum.css';
import LessonLayout from '../LessonLayout';

const SimplePendulum = () => {
  const sketchRef = useRef();
  const [length, setLength] = useState(200);
  const [gravity, setGravity] = useState(1);

  useEffect(() => {
    let angle = Math.PI / 4;
    let angleVelocity = 0;
    let angleAcceleration = 0;

    const sketch = (p) => {
      p.setup = () => {
        p.createCanvas(600, 400);
      };

      p.draw = () => {
        p.background(220);
        p.translate(300, 0);

        const x = length * Math.sin(angle);
        const y = length * Math.cos(angle);

        angleAcceleration = (-1 * gravity / length) * Math.sin(angle);
        angleVelocity += angleAcceleration;
        angle += angleVelocity;

        angleVelocity *= 0.99; // Add some damping

        p.stroke(0);
        p.strokeWeight(2);
        p.line(0, 0, x, y);
        p.fill(45, 123, 182);
        p.ellipse(x, y, 30, 30);
      };
    };

    const p5Instance = new p5(sketch, sketchRef.current);

    return () => {
      p5Instance.remove();
    };
  }, [length, gravity]);

  const content = (
    <div className="simple-pendulum">
      <h1>Simple Pendulum: Swinging into the World of Physics</h1>
      
      <section className="introduction">
        <h2>What is a Simple Pendulum?</h2>
        <p>A simple pendulum is a weight suspended from a pivot point that can swing freely. It's one of the most fundamental systems in physics, demonstrating key principles of motion, energy, and gravity. From grandfather clocks to playground swings, pendulums are all around us!</p>
      </section>

      <section className="simulation">
        <h2>See it in Action</h2>
        <p>Below is a simulation of a simple pendulum. Watch how it swings back and forth, converting potential energy to kinetic energy and back again.</p>
        <div ref={sketchRef}></div>
        <div className="controls">
          <label>
            Pendulum Length:
            <input 
              type="range" 
              min="100" 
              max="300" 
              value={length} 
              onChange={(e) => setLength(Number(e.target.value))}
            />
          </label>
          <label>
            Gravity:
            <input 
              type="range" 
              min="0.1" 
              max="2" 
              step="0.1" 
              value={gravity} 
              onChange={(e) => setGravity(Number(e.target.value))}
            />
          </label>
        </div>
      </section>

      <section className="explanation">
        <h2>How Does It Work?</h2>
        <p>The motion of a simple pendulum is governed by a few key factors:</p>
        <ul>
          <li><strong>Gravity:</strong> The force that pulls the pendulum down.</li>
          <li><strong>Length:</strong> The distance from the pivot point to the center of the bob (the weight at the end).</li>
          <li><strong>Initial displacement:</strong> How far from vertical the pendulum starts.</li>
        </ul>
        <p>As the pendulum swings, it continuously converts between two forms of energy:</p>
        <ul>
          <li><strong>Potential energy:</strong> Stored energy when the pendulum is at its highest points.</li>
          <li><strong>Kinetic energy:</strong> Energy of motion when the pendulum is moving, greatest at the bottom of its swing.</li>
        </ul>
      </section>

      <section className="real-world">
        <h2>Real-World Applications</h2>
        <p>Simple pendulums aren't just fun to watch—they have practical uses too!</p>
        <ul>
          <li><strong>Timekeeping:</strong> Pendulum clocks use the regular motion of pendulums to keep time.</li>
          <li><strong>Seismographs:</strong> Devices that measure earthquakes often use pendulums.</li>
          <li><strong>Amusement park rides:</strong> Many swinging rides are based on pendulum motion.</li>
        </ul>
      </section>

      <section className="experiment">
        <h2>Your Turn to Experiment</h2>
        <p>Use the sliders above the simulation to change the pendulum's length and the strength of gravity. What do you notice?</p>
        <ul>
          <li>How does changing the length affect the speed of the pendulum?</li>
          <li>What happens when you increase or decrease gravity?</li>
          <li>Can you make the pendulum swing faster? Slower?</li>
        </ul>
        <p>By playing with these variables, you're doing real physics experiments!</p>
      </section>
      
      <section className="conclusion">
        <h2>Wrapping Up</h2>
        <p>Simple pendulums are a gateway to understanding more complex systems in physics. They demonstrate fundamental principles like periodic motion, energy conversion, and the effects of gravity. As you continue your journey in physics, you'll find these concepts popping up again and again!</p>
      </section>
    </div>
  );

  return (
    <LessonLayout title="The Swinging Adventure of Simple Pendulums!">
      {content}
    </LessonLayout>
  );

};

export default SimplePendulum;
