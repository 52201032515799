import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/SimulationIndex.css';
import PageTransition from './PageTransition';

const simulations = [


  { id: 'threebodyproblem', title: 'Three Body Problem', description: 'Learn about three body problem' },
  { id: 'pendulum', title: 'Simple Pendulum', description: 'Learn about periodic motion and gravity' },
  // { id: 'double-pendulum', title: 'Double Pendulum', description: 'Explore chaos theory and complex motion' },
  // { id: 'wave-interference', title: 'Wave Interference', description: 'Understand wave superposition and interference patterns' },
  // { id: 'cellular-automata', title: 'Cellular Automata', description: 'Discover emergent patterns in simple rule-based systems' },
  // { id: 'fluid-dynamics', title: 'Fluid Dynamics', description: 'Visualize fluid flow and turbulence' },
  // { id: 'orbital-mechanics', title: 'Orbital Mechanics', description: 'Learn about planetary motion and gravitational effects' },
  // { id: 'particle-systems', title: 'Particle Systems', description: 'Simulate natural phenomena like fire, smoke, and explosions' },
  // { id: 'fractals', title: 'Fractal Generation', description: 'Explore self-similarity and complex geometric patterns' },
  // { id: 'neural-network', title: 'Neural Network Visualization', description: 'See how artificial neural networks learn and make decisions' },
  // { id: 'quantum-mechanics', title: 'Quantum Mechanics Basics', description: 'Visualize fundamental quantum concepts' }
];

const SimulationIndex = () => {
  return (
    <PageTransition>
      <div className="simulation-index">
        <h1>Interactive Simulations</h1>
        <p className="intro">Explore our collection of interactive simulations to deepen your understanding of complex concepts through hands-on experiences.</p>
        <div className="simulation-grid">
          {simulations.map((sim) => (
            <Link to={`/simulation/${sim.id}`} key={sim.id} className="simulation-card">
              <h2>{sim.title}</h2>
              <p>{sim.description}</p>
            </Link>
          ))}
        </div>
      </div>
    </PageTransition>
  );
};

export default SimulationIndex;