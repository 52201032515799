import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SimulationBookLanding from './components/SimulationBookLanding';
import SimulationIndex from './components/SimulationIndex';
import SimplePendulum from './components/simplependulum/SimplePendulum';
import ThreeBodyProblem from './components/threebodyproblem/ThreeBodyProblem';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<SimulationBookLanding />} />
        <Route path="/simulations" element={<SimulationIndex />} />
        <Route path="/simulation/pendulum" element={<SimplePendulum />} />
        <Route path="/simulation/threebodyproblem" element={<ThreeBodyProblem />} />
      </Routes>
    </Router>
  );
}

export default App;