export const createParticleSketch = (p5Instance, canvasRef) => (p) => {
  let particles = [];
  const PARTICLE_COUNT = 50;
  let mouse;

  p.setup = function() {
    let canvas = p.createCanvas(p.windowWidth, p.windowHeight);
    canvas.parent(canvasRef.current);
    mouse = p.createVector();
    for (let i = 0; i < PARTICLE_COUNT; i++) {
      particles.push(new Particle(p, p5Instance));
    }
  }

  p.draw = function() {
    p.clear();
    mouse.set(p.mouseX, p.mouseY);
    for (let particle of particles) {
      particle.update();
      particle.display();
    }
  }

  class Particle {
    constructor(p, p5) {
      this.p = p;
      this.p5 = p5;
      this.position = p.createVector(p.random(p.width), p.random(p.height));
      this.velocity = this.p5.Vector.random2D().mult(p.random(0.5, 2));
      this.acceleration = p.createVector();
      this.size = p.random(5, 15);
      this.maxSpeed = 4;
      this.color = p.color(
        p.random([30, 160, 224]),
        p.random([100, 200, 255]),
        p.random([100, 200, 255]),
        200
      );
    }

    update() {
      let mouseForce = this.p5.Vector.sub(mouse, this.position);
      let distance = mouseForce.mag();
      let strength = this.p.constrain(500 / (distance * distance), 0, 0.5);
      mouseForce.setMag(strength);
      this.acceleration.add(mouseForce);

      this.velocity.add(this.acceleration);
      this.velocity.limit(this.maxSpeed);
      this.position.add(this.velocity);
      this.acceleration.mult(0);

      if (this.position.x < 0) this.position.x = this.p.width;
      if (this.position.x > this.p.width) this.position.x = 0;
      if (this.position.y < 0) this.position.y = this.p.height;
      if (this.position.y > this.p.height) this.position.y = 0;
    }

    display() {
      this.p.noStroke();
      this.p.fill(this.color);
      this.p.circle(this.position.x, this.position.y, this.size);
    }
  }
};