import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import p5 from 'p5';
import '../styles/SimulationBookLanding.css';
import { createParticleSketch } from '../utils/particleSketch';
import PageTransition from './PageTransition';

const SimulationBookLanding = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const sketch = createParticleSketch(p5, canvasRef);
    new p5(sketch);

    return () => {
      if (canvasRef.current) {
        while (canvasRef.current.firstChild) {
          canvasRef.current.removeChild(canvasRef.current.firstChild);
        }
      }
    };
  }, []);

  return (
    <PageTransition>
      <div id="backgroundCanvas" ref={canvasRef}></div>
      <div className="container">
        <header>
          <div className="logo">simulationbook</div>
        </header>

        <section className="hero">
          <h1>Learn Through Interactive Simulations</h1>
          <p>Explore complex concepts with hands-on, visual learning experiences!</p>
          <Link to="/simulations" className="cta-button">Start Your Journey</Link>
        </section>

        <footer>
          <p>&copy; 2024 simulationbook.fun. All rights reserved.</p>
        </footer>
      </div>
    </PageTransition>
  );
};

export default SimulationBookLanding;